<template>
  <section id="main-wrapper" style="min-height: 95vh">
    <div class="container">
      <!-- End Navigation -->
      <div class="clearfix"></div>
      <!-- ============================================================== -->
      <!-- Top header  -->
      <!-- ============================================================== -->

      <!-- ============================ Page Title Start================================== -->
      <section class="page-title">
        <div class="container">
          <div class="row">
            <div class="col-lg-12 col-md-12">
              <div class="breadcrumbs-wrap">
                <h1 class="breadcrumb-title">Refund Policy</h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <!-- ============================ Page Title End ================================== -->

      <!-- ============================ Agency List Start ================================== -->
      <section>
        <!-- class="bg-light" -->
        <div class="container">
          <!-- row Start -->
          <div class="row">
            <div
              class="col-lg-12 col-md-12 text-justify"
            >
              <p>
                <span style="color: #83bf6f; font-weight: 700"
                  >971TUTORS.COM</span
                >
                accepts payments online using Visa and MasterCard credit/debit
                card in Arab Emirates Dirham currency. You must pay all fees
                payable to
                <span style="color: #83bf6f; font-weight: 700"
                  >971TUTORS.COM</span
                >
                (and any applicable charges) at the time of ordering your from
                <span style="color: #83bf6f; font-weight: 700"
                  >971TUTORS.COM</span
                >
                with a valid payment method on your shopping cart.

                <span style="color: #83bf6f; font-weight: 700"
                  >971TUTORS.COM</span
                >
                accepts no refunds after purchase and under any circumstance.
                Please contact us directly in case of defective items or
                incomplete orders.
              </p>
            </div>
          </div>
          <!-- /row -->
        </div>
      </section>
      <!-- ============================ Agency List End ================================== -->
    </div>
  </section>
</template>

<script>
export default {
  name: "cmp-refundpolicy",
  mounted() {
    setTimeout(() => {
      this.$store.commit("md_fire/mt_setLoading", false);
    }, 500);
  },
};
</script>

<style>
</style>